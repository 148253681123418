/*
* A dropdown's preferred contact list is made of of the contacts entered in the form builder
* multi_contact_no_options_unrestricted are multi contact lists that don't have any contacts in the preferred contact list.
* single_contact_no_options_unrestricted are single contact lists that don't have any contacts in the preferred contact list.
*/
import { RootState, SubmitFormState } from "../../app/store/state";
import {  SubmitFormActionType } from "../../app/store/actions";
import { Reducer, combineReducers } from "redux";
import { FormStatusType } from "../../app/store/sagas";
import { routerReducer } from 'react-router-redux';
import { FormDefinition, FormConfirmation, FormValues, FormComponentType, FormSubmissionResult, FormConfirmationType, SelectDataType, LogicDefinition, EventType, isLogicDefinitionArray, BooleanOperatorType, Contact, ValueType, InputComponentDefinition, areaCodeToCountryCode, countryPhoneFormat, ValidationTypes, TextInputDefinition } from "../../app/types";
import * as yup from 'yup'
import moment from 'moment'
import { Formik } from "formik";
import * as React from "react";
import { getDefaultDateValue, rtnFormattedLocale } from "../../app/utils";
import { CheckboxOption } from "@smartsheet/forms-components/esm/Checkbox/MultiCheckboxInput";

const initialState: SubmitFormState = {
  loading: false,
  form: undefined,
  error: undefined,
  initialValues: undefined,
  validationSchema: undefined,
  formikRef: undefined,
  submitting: false,
  submission: undefined,
  confirmation: undefined,
  formStatusType: FormStatusType.EMPTY,
  formToken: undefined,
  timeOfRender: undefined,
}

const CONTACT_TYPE = "CONTACT";
const STRING_TYPE = "STRING";
const DAYS_TYPE = "DAYS";
const DATE_TIME_TYPE = "DATE_TIME";
const RELATIVE_DATE_TIME_TYPE = 'RELATIVE_DATE_TIME';
const maxContactLimit = 20;let wasComponentTouched: {[key: string]: boolean} = {};

const emailRegexp = /^[a-z0-9!#$%&'*+/=?^_{|}~-]+(\.[a-z0-9,!#$%&'*+/=?^_{|}~-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,})$/i

const transformLogicValue = (predicate: any): any => {
  const value = predicate.value;
  const values = predicate.values;
  const start = predicate.start;
  const end = predicate.end;

  if(value && value.type) {
    if(value.type === STRING_TYPE) {
      // = and ; are encoded
      return value.value.replace(/%3B/g, ';').replace(/%3D/g, '=') as string;
    } else if(value.type === CONTACT_TYPE) {
      return value.value as Contact;
    } else if(value.type === RELATIVE_DATE_TIME_TYPE && value.value.unit === DAYS_TYPE) {
      return value.value.value as number;
    } else if(value.type === DATE_TIME_TYPE && value.value && typeof value.value === "string") {
      return value;
    }
  } else if(values && values.type) {
    if(values.type === STRING_TYPE) {
      return values.values as string[];
    } else if(values.type === CONTACT_TYPE) {
      return values.values as Contact[];
    }
  } else if(start && end) {
    return {start, end};
  }
  return value;
}
const transformLogicCondition = (predicate: any) => {
  let transformedLogicCondition: any = {};
  transformedLogicCondition.type = predicate.type;
  transformedLogicCondition.key = predicate.key;
  transformedLogicCondition.value = transformLogicValue(predicate);
  return transformedLogicCondition;
}

const transformLogicConditions = (predicate: any, transformedPredicate: any) => {
  let predicates = predicate.predicates;
  let notPredicate = predicate.predicate;
  transformedPredicate.type = predicate.type;
  if (predicates && Array.isArray(predicates) && predicates.length) {
    transformedPredicate.predicates = [];
    predicates.forEach((pred)=> {      
      let tempPred: any = null;
      if (pred.type === BooleanOperatorType.OR || pred.type === BooleanOperatorType.AND) {        
        tempPred = {type: pred.type};
        tempPred.predicate = transformLogicConditions(pred, {});        
      } else if(pred.type === BooleanOperatorType.NOT){
        tempPred = transformLogicConditions(pred, {});         
      } else {
        tempPred = transformLogicCondition(pred);         
      }
      transformedPredicate.predicates.push(tempPred);
    });
  } else if(notPredicate) {
    // For backward compatibility where NOT clause is stored as array
    if(Array.isArray(notPredicate)){
      transformedPredicate.predicate = transformLogicCondition(notPredicate);
    }else {
      transformedPredicate.predicate = [transformLogicCondition(notPredicate)];
    }        
  }
  return transformedPredicate;
}

/**
 * transformLogicRules is used to parse and transform logic rules 
 * from new version to version which is currently in use(production). This approach was 
 * taken instead of refactoring whole lot of code and also to maintain backwards compatibility.
 * @param logicArr 
 */
const transformLogicRules = (logicArr: any[]): LogicDefinition[] => {  
  let transformedLogicRules: any = [];
  if (Array.isArray(logicArr) && logicArr.length) {
    logicArr.forEach((logic)=>{
      let tempLogic: any = {type: logic.type};
      if(logic.predicate) {        
        tempLogic.predicate = transformLogicConditions(logic.predicate, {});
        transformedLogicRules.push(tempLogic);
      }
    });
  }
  return transformedLogicRules;
}

const parseLogic = (logicObj: any): LogicDefinition[] => {
  let logic: any = [];
  if (typeof logicObj === "string") {
    logic = JSON.parse(logicObj);
  } else if(logicObj) {
    logic = logicObj;
  }
  return transformLogicRules(logic) as LogicDefinition[];
}

const setHideConditionally = (logic: LogicDefinition[] | undefined, initialValues: FormValues):boolean => {
  return logic && isLogicDefinitionArray(logic, initialValues) ? logic[0].type === EventType.SHOW ? true : false : false;
}

export const submitFormReducer: Reducer<SubmitFormState> = (state = initialState, action) => {
  switch (action.type){
    case SubmitFormActionType.OPEN_FORM_REQUEST: {
      return { ...state, loading: true }
    }    
    case SubmitFormActionType.OPEN_FORM_SUCCESS: {
      const formPayload: FormDefinition = action.payload.form;

      const formPayloadCopy: FormDefinition = {...formPayload};

      const confirmation: FormConfirmation = action.payload.confirmation;
      const formToken: string = action.payload.formToken;
      const initialValues: FormValues = action.payload.initialValues
      const schemaEntries: {[key: string]: yup.Schema<any>} = {}
      moment.locale(rtnFormattedLocale(formPayload.userLocale))

      const configInitialValue = (initialValue: any, component: InputComponentDefinition<any>) => {
        if(!initialValues[component.key] || confirmation.type === FormConfirmationType.RELOAD){
          initialValues[component.key] = component.defaultValue ? component.defaultValue.value : initialValue;
        }  
      }

      // this function handles the required validation.
      // Many components utilizes the same validation, so
      // I consolidated them
      interface YupRequiredValidationParameters {
        component: InputComponentDefinition<any>;
        testName: string;
        errorMessage: string;
        multivalue?: boolean;
        checkboxValue?: boolean;
      }
      const yupRequiredValidation = ({
            component,
            testName,
            errorMessage,
            multivalue,
            checkboxValue
      }: YupRequiredValidationParameters) => {
            if (multivalue) {
                return component.required
                    ? yup.string().test(testName, errorMessage, function(value) {
                            if (component.required && !component.isHiddenByConditionalLogic) {
                                return yup.string().trim().required().isValid(value);
                            }
                            return yup.string().isValid(value);
                      })
                    : yup.array();
            } else if (checkboxValue) {
                return component.required
                    ? yup.boolean().test(testName, errorMessage, function(value) {
                            if (component.required && !component.isHiddenByConditionalLogic) {
                                return yup.boolean().oneOf([true]).required().isValid(value);
                            }
                            return yup.string().isValid(value);
                      })
                    : yup.string();
            } else {
                return component.required
                    ? yup.string().test(testName, errorMessage, function(value) {
                            if (component.required && !component.isHiddenByConditionalLogic) {
                                return yup.string().trim().required().isValid(value);
                            }
                            return yup.string().isValid(value);
                      })
                    : yup.string();
            }
        };

        // this will handle the email and required validation of contacts
        const handleValidationMultiContact = (component: InputComponentDefinition<any>, value: string, isContactLstNoOptions: boolean, maxContactLimit: number, emailMultiValueRegexp?: RegExp) => {
            // Without this required validation won't work, as on intial form load the value is empty, so split on it will return [''] instead of []
            const valueArr = (value.trim() === '') ? [] : value.trim().split(",");
                if (isContactLstNoOptions && !component.isHiddenByConditionalLogic && emailMultiValueRegexp) {
                  if (component.required) {
                    // require a non-empty valid email and no more than 20 contacts
                    return yup.array().max(maxContactLimit).of(                                                    
                      yup.string().lowercase().trim().required().matches(emailMultiValueRegexp)
                      ).isValid(valueArr);
                  } else {
                    // require a valid email empty or non-empty and have no more than 20 contacts
                    return yup.array().max(maxContactLimit).of(
                      yup.string().lowercase().trim().matches(emailMultiValueRegexp, { excludeEmptyString: true })
                      ).isValid(valueArr);          
                  }
                }
                // if contact list has prefererd contacts
                else if (!component.isHiddenByConditionalLogic) {
                  if(component.required){
                    return yup.array().max(maxContactLimit).required().isValid(valueArr)
                  } else {
                    return yup.array().max(maxContactLimit).isValid(valueArr);
                  }
                }
        
              return yup.string().isValid(value);
        }

        const handleValidationSingleContact = (component: InputComponentDefinition<any>, value: string, emailSingleValueRegexp: RegExp) => {
          const defaultContactOptionStringValue = "[object Object]";
          const validation = (component as any).validation;

          if(!component.isHiddenByConditionalLogic && emailSingleValueRegexp){
            if(value === defaultContactOptionStringValue){
              return true;
            }
            
            if(component.required){
              return (validation && validation.type === 'EMAIL') ?
                yup.string().lowercase().trim().required().matches(emailSingleValueRegexp).isValid(value)
                : 
                yup.string().lowercase().trim().required().isValid(value);
            } else {
              return (validation && validation.type === 'EMAIL') ?
              yup.string().lowercase().trim().matches(emailSingleValueRegexp).isValid(value)
              : 
              yup.string().lowercase().trim().isValid(value);
            }
          }
          return yup.string().isValid(value);
         }


        const handleValidationEmail = (component: InputComponentDefinition<any>, value: string, emailRegExp: RegExp) => {
          const validation = (component as any).validation;

          if(!component.isHiddenByConditionalLogic && emailRegExp){
            
            if(validation){
              const validationType = validation.type;
              if(component.required){
                return (validationType === ValidationTypes.EMAIL) ?
                  yup.string().lowercase().trim().required().matches(emailRegExp).isValid(value)
                  : 
                  yup.string().lowercase().trim().required().isValid(value);
              } else {
                return (validationType === ValidationTypes.EMAIL) ?
                yup.string().lowercase().trim().matches(emailRegExp).isValid(value)
                : 
                yup.string().lowercase().trim().isValid(value);
              }
            }
          }
          return yup.string().isValid(value);
         }

         const handleValidationPhone = (component: InputComponentDefinition<any>, value: string) => {
           if(!(component.key in wasComponentTouched)){
             wasComponentTouched[component.key] = false;
           }
           // Not required, so let it be submitted with empty value if not hidden
           if (!component.required && value == null) {
                if(component.defaultValue?.value && !wasComponentTouched[component.key] && !component.hidden){
                  return false;
                }
            return true
          }
          if (!component.isHiddenByConditionalLogic) {
            if(!wasComponentTouched[component.key]){
              wasComponentTouched[component.key] = true;
            }
            // If user hasn't input anything, then there will be a dash => good to submit it as is
            // but if they have entered the first digit, then now it has to match that phone format prior to submitting
            if (value) {
              const firstUserCharOffset = value.charAt(value.indexOf(" ") + 1) !== "(" ? 1 : 2;
              const firstCharofUserInput = value.indexOf(" ") + firstUserCharOffset

              const areaCode = value.split(" ")[0];
              const countryCode = areaCodeToCountryCode[areaCode];
              const countryPhoneFormatRegExp = new RegExp(countryPhoneFormat[countryCode]);

              // Not first user input number yet - should be fine to submit it if not required
              if (value.charAt(firstCharofUserInput) === '_') {
                  return !component.required
              } else { // Has a valid user input number, so should have all valid numbers as dictated by format
                return yup.string().matches(countryPhoneFormatRegExp).isValid(value);
              }
            }
            return false;
            //return value && value.charAt(firstCharofUserInput) !== '_' && value.charAt(lastCharofUserInput) !== '_';
          } else {
            return yup.string().isValid(value);
          }
         }

         const handleValidationDefaultValue = (component: TextInputDefinition) => {
          if(component.defaultValue && component.validation){
            const validationType = component.validation.type;
            const defaultValue = component.defaultValue;
            if(validationType === ValidationTypes.NUMERIC || validationType === ValidationTypes.PERCENTAGE){
              // https://regex101.com/r/eQXxaY/1/
              const NUM_REGEX = '^-?[\\d.]+(?:e-?\\d+)?';
              const numberRegExp = new RegExp(NUM_REGEX);

              if(!yup.string().matches(numberRegExp).isValidSync(defaultValue.value)){
                initialValues[component.key] = "";  
              } else {
                if(validationType === ValidationTypes.PERCENTAGE){
                  initialValues[component.key] = parseFloat(defaultValue.value)
                }
              }
            }
            else if(validationType === ValidationTypes.PHONE){
              const dirtyPhoneValuesRegExp = new RegExp(/[\s.+\-_()]/g)
              const cleanDefaultValue = defaultValue.value.replace(dirtyPhoneValuesRegExp, "");
              if(isNaN(cleanDefaultValue as any)){
                component.defaultValue.value = "";
                initialValues[component.key] = "";
              } else {
                component.defaultValue.value = cleanDefaultValue;
                initialValues[component.key] = cleanDefaultValue;
              }
            }
          }
         }
         

      formPayloadCopy.components.forEach(component => {
        switch(component.type){
          case FormComponentType.TEXT_INPUT: {
            /*if(component.defaultValue){
                initialValues[component.key] = component.defaultValue.value
              }*/
              //We attempted not setting defaults as shown above but this causes the form to not be blanked after submission which is needed for the formReset condition
              configInitialValue("", component);  
              const validationType = (component as any)?.validation?.type;

              // Email or Phone number to match format, or normal required validation,
              schemaEntries[component.key] = (validationType && validationType === ValidationTypes.EMAIL) ? yup.string()
              .test('text_input_email', 'email error', value => {
                return handleValidationEmail(component, value, emailRegexp);
               })
              :validationType && validationType === ValidationTypes.PHONE ? yup.string()
              .test('text_input_phone', 'phone error', (value: string) => {
                return handleValidationPhone(component, value)
              }): yupRequiredValidation({
                component,
                testName: "text_input",
                errorMessage: "message",
              });

              // section to handle default value
              handleValidationDefaultValue(component);

            break;
          }
          case FormComponentType.CHECKBOX_INPUT: {
            configInitialValue(false, component);   
            schemaEntries[component.key] = yupRequiredValidation({
              component,
              testName: "checkbox_input",
              errorMessage: "message",
              checkboxValue: true,
            });
            break;
          }
          case FormComponentType.MULTI_CHECKBOX_INPUT:{
            if(!initialValues[component.key] || confirmation.type === FormConfirmationType.RELOAD){
              const values: CheckboxOption[] = [];
              component.options.forEach(option => {
                  values.push({
                        value: option.value,
                        checked: component.defaultValue && component.defaultValue.value && Array.isArray(component.defaultValue.value) ?
                        (component.defaultValue!.value as any).indexOf(option.value) >= 0 : false
                  })
              });
              
              initialValues[component.key] = values
            }
            // If field is hidden by logic but required,
            // let the submission go through without requiring value for it
            schemaEntries[component.key] = component.required ? yup.array()
                  .test('multi_checkbox_input', 'required error', values => {
                    if (!component.isHiddenByConditionalLogic) {
                      return values.some((value: CheckboxOption) => value.checked)
                    } else {
                      return values.length;
                    }
                   }): yup.array();
            break;
          }

          case FormComponentType.SELECT_INPUT: {
            const validationType = (component as any)?.validation?.type;
            if(component.selectDataType === SelectDataType.MULTI_PICKLIST) {
              configInitialValue([], component);
              schemaEntries[component.key] = yupRequiredValidation({
                component,
                testName: "multi_select_input",
                errorMessage: "message",
                multivalue: true,
              });         
            // I am using valueType instead of selectDataType as I was told selectDataType will be phased out
            // so I should avoid using it    
            } else if (component.valueType === ValueType.CONTACT) {
              // restricted contact dropdown
              if (component.validateField) {
                // restricted multi contact dropdown
                if (component.multivalue) {
                  configInitialValue([], component);
                  schemaEntries[component.key] = 
                                            yup.string().test(
                                              "multi_contact_restricted",
                                              "required_or_max_length_exceeded",
                                              function(value: string) {
                                                return handleValidationMultiContact(component, value, false, maxContactLimit);
                                              }
                                            )
                } else {
                  // restricted single contact dropdown
                  configInitialValue("", component);    
                  schemaEntries[component.key] = yupRequiredValidation({
                    component,
                    testName: "single_select_input",
                    errorMessage: "message",
                  });
                }
              } else {
                // unrestricted dropdown
                // isContactLstNoOptions tracks if a contact has have a preferred contact list or not
                const isContactLstNoOptions = !component.options.length;

                // unresricted multi dropdown
                if (component.multivalue) {
                  configInitialValue([], component);
                  schemaEntries[component.key] =                                          
                                             yup.string().test(
                                              "multi_contact_unrestricted",
                                              "invalid_email_or_field_required_or_max_length_exceeded",
                                              function(value: string) {
                                                return handleValidationMultiContact(component, value, isContactLstNoOptions, maxContactLimit, emailRegexp);
                                              }
                                            )
                } else {
                  // unrestricted single dropdown
                  configInitialValue("", component); 
                  
                  schemaEntries[component.key] = yup.string().test(
                    "single_contact_unrestricted",
                    "invalid_email_or_field_required",
                    function(value: string) {
                      return handleValidationSingleContact(component, value, emailRegexp);
                  }
                )
                }
              }
            } else {
              configInitialValue("", component);    
              schemaEntries[component.key] = yupRequiredValidation({
                component,
                testName: "single_select_input",
                errorMessage: "message",
              });
            }
            break;
          }
          case FormComponentType.RADIO_INPUT: {
            if(!initialValues[component.key] || confirmation.type === FormConfirmationType.RELOAD){
              initialValues[component.key] = component.defaultValue ? component.defaultValue.value : ""
            }    
            schemaEntries[component.key] = yupRequiredValidation({
              component,
              testName: "radio_input",
              errorMessage: "message",
            });
            break;
          }
          case FormComponentType.DATE_INPUT: {
            if(!initialValues[component.key] || confirmation.type === FormConfirmationType.RELOAD) {
              if(component.defaultValue) {
                initialValues[component.key] = getDefaultDateValue(component.defaultValue.type, component.defaultValue.value)
              } else {
                initialValues[component.key] = ""
              }
            }

            if (component.validateField) {
              schemaEntries[component.key] = yup.string().test("date_input", "message", (context: string, value?: string) => {
                if (!component.required) {
                  if (!context || (context && context === "")) {
                    return true;
                  }
                }
                return !component.isHiddenByConditionalLogic ? moment(context, "L", true).isValid(): true;
              })
            }
            else {
              schemaEntries[component.key] = yupRequiredValidation({
                component,
                testName: "text_input",
                errorMessage: "message",
              });
            }
            break;
          }
          case FormComponentType.EMAIL_RECEIPT_INPUT: {
            if(component.defaultValue) {
              if(component.displayDefaultValue) {
                initialValues[component.key] = { emailRequested: component.displayDefaultValue, email: component.defaultValue.value }
              } else {
                initialValues[component.key] = { emailRequested: false, email: component.defaultValue.value }
              }
            }
            else {
              initialValues[component.key] = { emailRequested: false, email: ""}
            }
            schemaEntries[component.key] = yup.object({
              emailRequested: yup.boolean(),
              email: yup.string().when(
                'emailRequested', {
                  is: true,
                  then: yup.string().email().required()
                }
              )
            });            
            break;
          }
          case FormComponentType.CAPTCHA: {
            // mapping Captcha component to captcha property on the form so that it can be easily used in SubmitFormApp/index.tsx
            // Where this component will be used to show invisible captcha.
            formPayloadCopy.captcha = component;
            break;
          } 
          case FormComponentType.FILE_UPLOAD: {
            initialValues[component.key] = "";
            schemaEntries[component.key] = yupRequiredValidation({
              component,
              testName: "file_upload",
              errorMessage: "message",
            });
            break;
          }
        }
      })

      formPayloadCopy.components.forEach(component => {
        component.logic = parseLogic(component.logic);
        component.isHiddenByConditionalLogic = setHideConditionally(component.logic, initialValues);
      });

      state.formikRef && state.formikRef.current && state.formikRef.current.resetForm();
      return { ...state, submitting: false, loading: false, form: formPayloadCopy, formStatusType: FormStatusType.SUCCESS, formToken, confirmation, initialValues, validationSchema: yup.object(schemaEntries), formikRef: React.createRef<Formik>(), timeOfRender: new Date() }
    }
    case SubmitFormActionType.OPEN_FORM_FAILURE: {
      return { ...state, loading: false, error: action.payload }
    }

    case SubmitFormActionType.SUBMIT_FORM_REQUEST: {
      wasComponentTouched = {};
      return { ...state, confirmation: undefined, submitting: true }
    }

    // We are no longer using this Action in our code, as it is similar to OPEN_FORM_SUCCESS.
    case SubmitFormActionType.SUBMIT_FORM_SUCCESS: {
      const submissionResult: FormSubmissionResult = action.payload.submissionResult;
      const prevValues = action.payload.rawValues;
      const { confirmation } = submissionResult;
      const updatedFormDefinition = action.payload.updatedFormDefinition

      switch (confirmation.type) {
        case FormConfirmationType.MESSAGE:
        case FormConfirmationType.REDIRECT: {
          return { ...state, submitting: false, confirmation, formToken: undefined, initialValues: prevValues, error: undefined, timeOfRender: new Date(), form: updatedFormDefinition ? updatedFormDefinition : state.form };
        }
        case FormConfirmationType.RELOAD: {
          state.formikRef && state.formikRef.current && state.formikRef.current.resetForm()
          return { ...state, submitting: false, confirmation, formToken: undefined, error: undefined, timeOfRender: new Date(), form: (updatedFormDefinition ? updatedFormDefinition : state.form), formikRef: React.createRef<Formik>() };
        }
      }
    }

    case SubmitFormActionType.SUBMIT_FORM_FAILURE : {
      return { ...state, error: action.payload, formStatusType: FormStatusType.FAILURE, submitting: false, formToken: undefined }
    }

    case SubmitFormActionType.UPDATE_FORM_REQUEST: {
      return {...state, form: action.payload.form }
    }
    
    default: {
      return state;
    }
  }
}

export const rootReducer = combineReducers<RootState>({
  submitForm: submitFormReducer as any,
  router: routerReducer as any
});

