import { FormDefinition } from '../../app/types';
import axios from 'axios';
import { UiMetric } from '@smartsheet/forms-components';
import moment from 'moment';

export function omit<T extends object, K extends keyof T>(target: T, ...omitKeys: K[]): Omit<T, K> {
  return (Object.keys(target) as K[]).reduce(
    (res, key) => {
      if (omitKeys.indexOf(key) === -1) {
        res[key] = target[key];
      }
      return res;
    },
    {} as any
  );
}


export const createPropsGetter = <DP extends object>(defaultProps: DP) => {
  return <P extends Partial<DP>>(props: P) => {
    type PropsExcludingDefaults = Pick<P, Exclude<keyof P, keyof DP>>;

    type RecomposedProps = DP & PropsExcludingDefaults;

    return {...defaultProps, ...props } as RecomposedProps;
  };
};

export const submitMetrics = (body: UiMetric) => {
  const API_ENDPOINT = process.env.FORM_API_ENDPOINT || window.formEndpoint || "http://localhost:17610"

  axios.request<String>({
    baseURL: API_ENDPOINT,
    url: `/api/public/uimetrics`,
    method: "POST",
    headers: {
        "x-smar-submission-token": window.formToken
    },
    data: body
  })
};
export const resolveApiUrl = (path: string): string => {
  if (window.formEndpoint) {
    return window.formEndpoint + path 
  }
  return path;
}

export const resolveAssetUrl = (path: string): string => {
  if (window.formEndpoint && !path.startsWith("data:")) {
    return window.formEndpoint  + path
  }
  return path;
}

export function rtnFormattedLocale(locale: string){
  //defensive coding is not done intentionally, no undefined check
  const server_locale_pattern = /[a-z]{2}_[A-Z]{2}$/gim;
  if(server_locale_pattern.test(locale)){
    locale = locale.replace('_', '-');
  }

  switch(locale) {
    case "no-NO":
      //Norway needs to be manually remapped, datepicker has Norwegian language code as "nb" and app-core has it as "no"
      locale = "nb-NO"
      break;
    case "iw-IL":
      // iw-IL is "Hebrew - Israel", iw is an old language code so mapping it to the modern "he"
      locale = "he-IL"
      break;   
    case "es-HN":
    case "es-NI":
    case "es-PR":
      // "Spanish - El Salvador" has been added to support Spanish with the "MM-DD-YYYY" format, mapping other similar contries to it
      locale = "es-SV"
      break;
    case "es-PA":
      // "Spanish - Panama" matches the MM/dd/YYYY format of "Spanish - US"
      locale = "es-US"
      break;
    case "en-IN":
    case "ar-BH":
    case "ar-EG":
    case "ar-IQ":
    case "ar-JO":
    case "ar-KW":
    case "ar-OM":
    case "ar-QA":
    case "ar-SA":
    case "ar-SD":
    case "ar-SY":
    case "ar-AE":
    case "ar-YE":
    case "zh-SG":
          // "Chinese - Singapore" in app-core does not match the date format of any other chinese locale so falling back to English and mapping to en-GB 
    // as we do not currently support Chinese translations.  Will need an update when smartsheet supports Chinese      
            // Mapping all Arabic languages that use a period for numerics to GB english, loses translations but provides data consistency
      // TO DO: The set below will need to be updated if Smartsheet ever adds translation support for Arabic
      locale = "en-GB"
      break;
    case "ar-LB":
    case "ar-LY":
      // ar-MA (Morocco), ar-TN (Tunisia) are also a valid Arabic comma separated cases that do not require mappings
      // ar-DZ (Algeria) uses commas for numerics but also enders the datepicker with arabic text and actual numbers
      locale = "ar-DZ"
      break;
    case "hi-US":   
      // "hi-US" is called "hindi (India)" in app-core but it mapped to the US MM/dd/YY date format
      locale = "en-US"
      break;
  }
  return locale;
}


// dynamically determine the logo color based on pass in background color hex
export const isDarkBackground = (hexColorStr?: string): boolean => {
  const DEFAULT_DARK_BACKGROUND_LIGHTNESS = 165;

  if (!hexColorStr || !/^#[0-9A-F]{6}$/i.test(hexColorStr)) {
    return false;
  }

  const cleanHexStr = hexColorStr.replace(/^#/, '');
  const integer = parseInt(cleanHexStr, 16);
  const r = (integer & 0xff0000) >> 16;
  const g = (integer & 0xff00) >> 8;
  const b = integer & 0xff;

  // Relative luminance formula -> from app-core
  const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return brightness <= DEFAULT_DARK_BACKGROUND_LIGHTNESS;
};

export const isModernLayout = (form?: FormDefinition): boolean => {
  if (!form) {
    return false;
  }

  const { hideTitleAndDescription, theme } = form;

  if (!theme) {
    return false;
  }

  const themeIsModern = theme.type === 'MODERN';

  const noLogo = theme.style && theme.style.logo && theme.style.logo.image === 'none';
  
  /*
    check to see if a background image has been set. 
    if a bg image has been set and theme.type === 'Modern', isModernLayout will return true
  */
  const noBgImage = theme.style && theme.style.background && !theme.style.background.hasOwnProperty('image'); 

  return themeIsModern  && !(noLogo && hideTitleAndDescription && noBgImage); 
};

export const getDefaultDateValue = (type : string, value: string) => {
  return type === "DATE_TIME" || moment(value).isValid() ? moment.utc(value).format("L") : value
}